@keyframes success {
  0% {
    transform: none;
  }
  100% {
    transform: scale(0.5) translateX(4000px) rotateZ(45deg);
  }
}

@keyframes fail {
  0% {
    transform: none;
  }
  100% {
    transform: scale(0.5) translateX(-4000px) rotateZ(-45deg);
  }
}

@keyframes success--mobile {
  0% {
    transform: none;
  }
  100% {
    transform: translateX(900px) rotateZ(45deg);
  }
}

@keyframes fail--mobile {
  0% {
    transform: none;
  }
  100% {
    transform: scale(0.5) translateX(-900px) rotateZ(-45deg);
  }
}

.roa-stack {
  position: relative;
  transition: all 100ms;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-container {
    width: 100%;
    padding: 40px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    &--mobile {
      width: 100%;
      padding: 10px;
      // margin-top: -100px;
      box-sizing: border-box;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      display: none;
    }

    &-points {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding: 0;
      max-width: 400px;
      gap: 20px;
      & * {
        margin: 0;
        text-align: center;
      }
      & h2 {
        font-size: 36px;
      }

      & button {
        border-style: none;
        height: 58px;
        border-radius: 0;
        padding: 4px 28px;
        font-size: 18px;
        cursor: pointer;
        border: 2px solid #667085;
        border-radius: 3px;
        background: linear-gradient(#ffffff, #f8f8f8);
        &:hover {
          background: #01f3b3 !important;
          border: 2px solid #01f3b3 !important;
        }
      }
    }
    &-success {
      height: calc(100vh - 64px);
      width: 40vw;
      position: fixed;
      z-index: 9999999999;
      right: 0;
      top: 64px;
      background: linear-gradient(90deg, #01f3b200, #01f3b3);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > h2 {
        font-size: 96px;
        color: #fff;
      }
      &--mobile {
        height: calc(100vh - 56px);
        width: 100vw;
        position: fixed;
        z-index: 9999999999;
        right: 0;
        top: 56px;
        background: linear-gradient(90deg, #01f3b200, #01f3b3);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & > h2 {
          margin-top: -56px;
          font-size: 48px;
          color: #fff;
        }
      }
    }
    &-fail {
      height: calc(100vh - 64px);
      width: 40vw;
      position: fixed;
      z-index: 9999999999;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 64px;
      & > h2 {
        font-size: 96px;
        color: #fff;
      }
      background: linear-gradient(90deg, rgb(243, 1, 66, 1), #f3014200);

      &--mobile {
        height: calc(100vh - 56px);
        width: 100vw;
        position: fixed;
        z-index: 9999999999;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 56px;
        & > h2 {
          font-size: 48px;
          margin-top: -56px;
          color: #fff;
        }
        background: linear-gradient(90deg, rgb(243, 1, 66, 1), #f3014200);
      }
    }
  }

  &-children {
    position: absolute;
    transition: all 100ms;
    top: 80px;
    z-index: 99;

    overflow: hidden;
  }

  &-success {
    animation: success ease-in 500ms forwards;
    &--mobile {
      animation: success--mobile ease-in 800ms forwards;
    }
  }

  &-fail {
    animation: fail ease-in 500ms forwards;
    &--mobile {
      animation: fail--mobile ease-in 800ms forwards;
    }
  }
}

@media screen and (max-width: 900px) {
  .roa-stack-container {
    display: none;
    &--mobile {
      display: flex;
    }
  }
}
