.roa-layout {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: hidden;
  position: absolute;
  left: 0;

  &-langs {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
    gap: 4px;
    & button {
      height: 32px;
      width: 32px;
      min-height: 32px;
      max-height: 32px;
      min-width: 32px;
      max-width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      border-style: none;
      border: 1px solid #2b2b2b;
      border-radius: 3px;
      cursor: pointer;
      background-color: transparent;
    }
  }
}
