@import "colors.sass"

html
    scroll-behavior: smooth

body
    margin: 0
    font-family: "Roboto", "Helvetica", "Arial", sans-serif
    font-size: 0.9rem
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    height: auto
    background: $background
    color: $color-text
    overflow: auto
    
.app

    #content
        margin: auto
        padding: 0

    input, textarea
        font-size: 15px

    .list-results > span
        font-size: 15px

    .twitter-tweet-rendered
        margin: 10px auto

    .logo
        margin-left: 10px
        cursor: pointer

    .logo-lg
        width: 220px
        display: block

    .logo-sm
        width: 140px
        display: none


@media (max-width: 480px)

    .app
        .logo-lg
            display: none

        .logo-sm
            display: block
