.roa-footer {
  width: 100%;
  display: flex;
  box-sizing: border-box !important;
  justify-content: space-around;
  align-items: center;
  margin-top: 150px;
  margin-bottom: 100px;
  z-index: 100;
  &-container {
    width: 100%;
    padding: 40px;
    display: flex;
    box-sizing: border-box !important;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 900px) {
  .roa-footer {
    flex-direction: column-reverse;
    gap: 40px;
  }
}
