@import '../../styles/variables.scss';

.roa-page {
  box-sizing: border-box;
  width: calc(100% - 40px);
  max-width: 900px;
  margin: 40px;
  background-color: #ffffff;
  border-radius: 0px;
  border: 1px solid #ededed;
  padding: 40px;
  height: 1240px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
  box-shadow:
    0px 0px 0px 0px rgba(0, 0, 0, 0.01),
    0px 33px 73px 0px rgba(0, 0, 0, 0.01),
    0px 132px 132px 0px rgba(0, 0, 0, 0.04),
    0px 297px 178px 0px rgba(0, 0, 0, 0.02),
    0px 529px 212px 0px rgba(0, 0, 0, 0.001),
    0px 826px 231px 0px rgba(0, 0, 0, 0);
  &-header {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    justify-items: center;
    align-items: center;
    &-t {
      width: 100%;
      display: grid;
      grid-template-columns: 80px auto 80px;
      justify-items: center;
      align-items: center;
      border-bottom: 2px solid #1d2939;
      padding-bottom: 8px;
      &__langs {
        width: 80px !important;
        & button {
          width: 32px;
          height: 32px;
          margin: 0;
          padding: 0;
        }
      }
      & * {
        font-family: 'Abhaya Libre', serif;
        font-weight: 900;
        font-style: normal;
        color: #1d2939;
      }
    }
    &-b {
      width: 100%;
      text-align: center;
      border-bottom: 14px solid #1d2939;
      line-height: 96px;
      text-align: center;
      font-family: 'Abhaya Libre', serif;
      font-weight: 800;
      font-style: normal;
      font-size: 96px;
      margin: 0;
      margin-top: 12px;
      & span {
        color: #01f3b3;
      }
    }
    &-description {
      width: 100%;
      margin-top: 20px;
      background-color: #1d2939;
      padding: 14px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      & > span {
        color: #fcfcfd;
        font-family: 'Abhaya Libre', serif;
        font-weight: 600;
        font-style: normal;
        font-size: 18px;
      }
    }
  }
  &-article {
    width: 100%;
    display: grid;
    grid-template-columns: calc(70% - 40px) calc(30% - 20px);
    gap: 40px;
    box-sizing: border-box;
    justify-items: flex-start;
    align-items: flex-start;
    margin-top: 40px;
    & section {
      width: 100%;
      display: grid;
      grid-template-columns: 100%;
      justify-items: flex-start;
      align-items: flex-start;
      gap: 30px;
      height: 890px;
      & > article {
        display: grid;
        width: 100%;
        gap: 30px;
        grid-template-columns: 100%;
        justify-items: flex-start;
        align-items: flex-start;
        & h2 {
          margin: 0;
          font-family: 'Abhaya Libre', serif;
          font-weight: 800;
          color: #000;
          line-height: 30px;
          font-size: 28px;
        }
        & p {
          margin: 0;
          font-family: 'Abhaya Libre', serif;
          font-weight: 500;
          white-space: pre-line;
          line-height: 22px;
          color: #000;
          font-size: 16px;
        }
      }
    }
    &-buttons {
      display: flex;
      box-sizing: border-box;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      background-color: #fcfcfd;
      padding: 40px;
      & h3 {
        margin: 0;
        font-family: 'Abhaya Libre', serif;
        font-weight: 800;
        color: #000;
        line-height: 30px;
        font-size: 24px;
      }
      & div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        & button {
          border-style: none;
          height: 58px;
          border-radius: 0;
          padding: 4px 28px;
          font-size: 18px;
          cursor: pointer;
          border: 2px solid #667085;
          color: #1d2939;
          border-radius: 3px;
          background: linear-gradient(#ffffff, #f8f8f8);
          &:hover {
            background: #01f3b3 !important;
            border: 2px solid #01f3b3 !important;
          }
        }
      }
    }
  }
  &-dummy {
    width: 100%;
    height: 180px;
    box-sizing: border-box;
    background-color: #eaecf0;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    & > div {
      display: flex;
      flex-wrap: wrap;
      gap: 3px;
      justify-content: flex-start;
      align-items: flex-start;
      & div {
        height: 10px;
        border-radius: 3px;
        background-color: #98a2b3;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .roa-page {
    width: 100% !important;
    box-sizing: border-box !important;
    margin: 0 !important;
    min-height: 1900px;
    padding: 20px 10px 10px 10px !important;
    &-article {
      grid-template-columns: 100%;
      &-r {
        display: none !important;
      }
      &-l {
        border-right: none !important;
        padding-right: 0 !important;
      }
    }
  }
}
